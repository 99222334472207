<template>
    <div class="hdScreenChart"></div>
</template>

<script>
export default {
    name: '',
    data() {
        return {}
    },
    methods: {
        setChart() {
            this.$http
                .get('https://hr.estar-info.com/hrApiController.do?customerCatagory')
                .then(
                    response => {//successcallback


                        let option = {
                            xAxis: {
                                type: 'category',
                                data: ['复星集团','太平金科','浙商财险','睿祈','国元保险','中华保险','都邦保险','北部湾'],
                                splitLine:{
                                    show:false
                                },
                                axisLabel: {
                                    color: '#61B9C8',
                                    fontSize: 9,
                                    interval: 0,
                                    rotate: 38

                                },
                            },
                            tooltip: {
                                trigger: 'axis',
                            },
                            grid: {
                                top: '12%',
                                left: '10%',
                                right: '10%',
                                bottom: '10%',
                                containLabel: true
                            },
                            barWidth: 20,
                            yAxis: {
                                type: 'value',
                                splitLine:{
                                    show:false
                                },
                                axisLabel: {
                                    color: '#61B9C8',
                                    fontSize: 9
                                },
                            },
                            series: [
                                {
                                    data: [
                                        {
                                            value: response.body.obj.fosun,
                                            itemStyle: {
                                                color: '#c2232a'
                                            }
                                        },
                                        {
                                            value: response.body.obj.etaiping,
                                            itemStyle: {
                                                color: '#fe672c'
                                            }
                                        },
                                        {
                                            value: response.body.obj.zs,
                                            itemStyle: {
                                                color: '#2870e8'
                                            }
                                        },
                                        {
                                            value: response.body.obj.rq,
                                            itemStyle: {
                                                color: '#61B9C8'
                                            }
                                        },
                                        {
                                            value: response.body.obj.gy,
                                            itemStyle: {
                                                color: '#a262f2'
                                            }
                                        },
                                        {
                                            value: response.body.obj.zhonghua,
                                            itemStyle: {
                                                color: '#11367a'
                                            }
                                        },
                                        {
                                            value: response.body.obj.dubang,
                                            itemStyle: {
                                                color: '#c2232a'
                                            }
                                        },
                                        {
                                            value: response.body.obj.bbw,
                                            itemStyle: {
                                                color: '#c2232a'
                                            }
                                        }


                                    ],
                                    type: 'bar'
                                }
                            ]
                        };

                        let myChart = this.$echarts(this.$el);
                        myChart.clear();
                        myChart.resize()
                        myChart.setOption(option);
                    },
                    response => {//errorcallback
                        alert("失败！");
                        console.log(JSON.stringify(response))//错误信息
                    })



        }
    },
    mounted() {
        this.setChart()
    },
}
</script>

<style lang="less" scoped>
.hdScreenChart {
    height: 100%;
    width: 100%;
}
</style>
